import React, { useRef } from 'react'
import { Button, Grid, Container, Box, Typography, IconButton, useMediaQuery } from '@mui/material'
import { KeyboardDoubleArrowRight, KeyboardDoubleArrowLeft } from '@mui/icons-material';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import { handleTyC, handlePrivacidad } from '../Footer'

const steps = [
  { id: 1, image: 'https://danone50anos-cdn.mywebserver.in/assets2/svg/Step1.svg' },
  { id: 2, image: 'https://danone50anos-cdn.mywebserver.in/assets2/svg/Step2.svg' },
  { id: 3, image: 'https://danone50anos-cdn.mywebserver.in/assets2/svg/Step3.svg' },
];

var items = [
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Step1.svg'} alt='Step1' style={{ width: '100%', maxHeight: '15vh', margin: 1 }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Step2.svg'} alt='Step2' style={{ width: '100%', maxHeight: '15vh', margin: 1 }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Step3.svg'} alt='Step3' style={{ width: '100%', maxHeight: '15vh', margin: 1 }} />
]

const Step = ({ step }) => {
  return (
    <Grid item xs={4}>
      <img src={step.image} alt={step.label} style={{ width: '100%', height: 'auto' }} />
    </Grid>
  );
};

const renderItems = () => {
  let images = items.map((item, index) => (
    <div key={index} style={{ width: '10rem', height: '10rem', margin: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
      {item}
    </div >
  ));

  return images;
};

const Steps = () => {
  const xs = useMediaQuery('(max-width: 899px)');
  const md = useMediaQuery('(min-width: 900px)');

  const carousel = useRef(null);

  return (
    <Grid container item spacing={2} direction={"column"} sx={{ marginTop: { xs: 5, md: 10 } }}>
      <Grid container item spacing={2} direction={'row'} sx={{ padding: 0, marginTop: { xs: -5, md: 0 } }}>
        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} md={8} display={'flex'} justifyContent={'center'}>
          <img
            src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/RegistraYgana.svg'}
            alt="RegistraYGana"
            style={{ width: "90%" }}
          />
        </Grid>
        <Grid item xs={0} md={2}></Grid>
      </Grid>

      {xs && (
        <Grid container item spacing={2} direction={'row'} sx={{ padding: 0, marginTop: { xs: -2, md: 0 } }}>
          <Grid item xs={1} md={2}></Grid>

          <Grid
            item
            xs={10}
            md={8}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ borderColor: '#C4E7F9', borderStyle: 'solid', bordderWidth: 4, borderRadius: 15, backgroundColor: 'rgba(196,231,249,0.5)' }}
            style={{ paddingLeft: '5rem', paddingRight: '5rem' }}>


            <IconButton
              color='secondary'
              onClick={(e) => {
                carousel?.current?.slidePrev(e)
              }}
            >
              <KeyboardDoubleArrowLeft sx={{ fontSize: 72, zIndex: 10 }} />
            </IconButton>

            <AliceCarousel
              autoHeight
              autoWidth
              disableDotsControls
              disableButtonsControls
              items={renderItems()}
              ref={carousel}
            />

            <IconButton
              color='secondary'
              onClick={(e) => {
                carousel?.current?.slideNext(e)
              }}
            >
              <KeyboardDoubleArrowRight sx={{ fontSize: 72, zIndex: 10 }} />
            </IconButton>


          </Grid>
          <Grid item xs={1} md={2}></Grid>
        </Grid>
      )}
      {md && (

        <Grid container item spacing={2} direction={'row'} sx={{ padding: 0, marginTop: { xs: -5, md: 0 } }}>
          <Grid item xs={0} md={2}></Grid>
          <Grid item xs={12} md={8} display={'flex'} justifyContent={'center'}>

            <Grid container item spacing={2} xs={12} margin={1} style={{ paddingLeft: 0 }}>
              {steps.map((step) => (
                <Step key={step.id} step={step} />
              ))}
            </Grid>

          </Grid>
          <Grid item xs={0} md={2}></Grid>
        </Grid>
      )}


      <Grid item xs={12} display={"flex"} justifyContent={"center"} sx={{ marginBottom: 5 }}>
        <Typography color={'white'} fontWeight={'bold'} sx={{ fontSize: { xs: 10, md: 15 } }} component={"span"}>*Consulta&nbsp;</Typography>
        <Typography
          color={'white'}
          fontWeight={'bold'}
          sx={{
            fontSize: { xs: 10, md: 15 }, cursor: 'pointer', ":hover": { textDecoration: 'underline' }
          }}
          component={"span"}
          onClick={() => { handleTyC() }}
        >términos y condiciones</Typography>
        <Typography color={'white'} fontWeight={'bold'} sx={{ fontSize: { xs: 10, md: 15 } }} component={"span"}>&nbsp;y&nbsp;</Typography>
        <Typography
          color={'white'}
          fontWeight={'bold'}
          sx={{
            fontSize: { xs: 10, md: 15 }, cursor: 'pointer', ":hover": { textDecoration: 'underline' }
          }}
          component={"span"}
          onClick={() => { handlePrivacidad() }}
        >aviso de privacidad</Typography>
        <Typography color={'white'} fontWeight={'bold'} sx={{ fontSize: { xs: 10, md: 15 } }} component={"span"}>.</Typography>
      </Grid>
    </Grid>

  )
}

export { Steps };
