import React, { useState, useEffect } from 'react'
import { Button, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Card, CardContent, Typography, IconButton } from '@mui/material'
import { KeyboardDoubleArrowRight, KeyboardDoubleArrowLeft } from '@mui/icons-material';
import { weeks } from './weeks';
import axios from 'axios';

const Ranking = () => {
  const [weekIndex, setweekIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(0);
  const [week, setWeek] = useState(weeks[0]);

  const [data, setData] = useState([]);

  const urlWebApi = 'https://api-landing-9838hd.mywebserver.in/api/v1/'
  const handleGetRanking = () => {

    axios.get(urlWebApi + 'auth/ranking', {
      params: {
        startDate: week.dateInit.toISOString(),
        endDate: week.dateEnd.toISOString()
      }
    }).then((response) => {
      const { success, data } = response.data
      setData(data)
    })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    handleGetRanking()
  }, [weekIndex]);

  useEffect(() => {
    const today = new Date();
    const currentWeek = weeks.findIndex(x => x.dateInit >= today && today <= x.dateEnd)
    setLastIndex(currentWeek - 1)
    setweekIndex(currentWeek - 1)
    setWeek(weeks[currentWeek - 1])
    console.log(weeks[currentWeek - 1])
  }, []);

  return (
    <Grid container item spacing={2} direction={"column"} sx={{ marginTop: { xs: 5, md: 10 } }} id='Ganadores'>
      <Grid container item spacing={2} direction={'row'} sx={{ padding: 0, marginTop: { xs: -6, md: 0 } }}>
        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} md={8} display={'flex'} justifyContent={'center'}>
          <img
            src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Ganadores.svg'}
            alt='Ganadores'
            style={{ width: "50%" }}
          />
        </Grid>
        <Grid item xs={0} md={2}></Grid>
      </Grid>

      <Grid container item spacing={2} direction={'row'} sx={{ padding: 0, marginTop: { xs: -3, md: 0 } }}>
        <Grid item xs={1} md={2}></Grid>
        <Grid item xs={10} md={8} display={'flex'} justifyContent={'center'}>
          <Card sx={{ minHeight: 350, width: '100%' }}>
            <CardContent>
              <Grid container spacing={2} direction={'row'} sx={{ padding: 0, marginBottom: 2 }}>
                <Grid item xs={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                  <IconButton disabled={weekIndex == 0} color='primary'>
                    <KeyboardDoubleArrowLeft
                      sx={{ fontSize: 72, zIndex: 10 }}
                      onClick={(e) => {
                        setweekIndex(weekIndex - 1);
                        setWeek(weeks[weekIndex - 1]);
                      }}
                    />
                  </IconButton>
                </Grid>
                <Grid item xs={8} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                  <Typography variant='h4' sx={{ fontWeight: 'bold', fontSize: { xs: 18, md: 32 } }}>{week.week}</Typography>
                </Grid>
                <Grid item xs={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                  <IconButton disabled={weekIndex == lastIndex} color='primary'>
                    <KeyboardDoubleArrowRight
                      sx={{ fontSize: 72, zIndex: 10 }}
                      onClick={(e) => {
                        setweekIndex(weekIndex + 1);
                        setWeek(weeks[weekIndex + 1]);
                      }}
                    />
                  </IconButton>
                </Grid>
              </Grid>

              <TableContainer component={Paper} sx={{ backgroundColor: '#003387', minWidth: '100%', maxHeight: 350, minHeight: 350 }}>
                <Table>
                  <TableBody>
                    {week.winner != '' &&
                      <TableRow
                        key={'weekWin'}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, borderBottom: '3px solid #13206D' }}
                      >
                        <TableCell align='center' size='medium' width={'50%'} sx={{ color: '#FFDB00', fontWeight: 'bold' }} >
                          {week.winner.toUpperCase()}
                        </TableCell>
                        <TableCell align="center" size='medium' width={'50%'} sx={{ color: '#FFDB00', fontWeight: 'bold' }}>
                          Premio Semanal
                        </TableCell>
                      </TableRow>
                    }
                    {data.length == 0 && week.winner == '' && week.dailyWinner.length == 0 ?
                      <TableRow
                        key={'empty'}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, borderBottom: '3px solid #13206D' }}
                      >
                        <TableCell align='center' size='medium' width={'100%'} sx={{ color: '#FFDB00', fontWeight: 'bold' }} colSpan={2}>
                          Validando premios...
                        </TableCell>
                      </TableRow>
                      :
                      data.map((row, index) => (
                      <TableRow
                          key={index + "giftDaily"}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, borderBottom: '3px solid #13206D' }}
                      >
                        <TableCell align='center' size='medium' width={'50%'} sx={{ color: '#FFDB00', fontWeight: 'bold' }} >
                            {row.upper}
                        </TableCell>
                        <TableCell align="center" size='medium' width={'50%'} sx={{ color: '#FFDB00', fontWeight: 'bold' }}>
                          {row.description}
                        </TableCell>
                      </TableRow>
                    ))}
                    {
                      week.dailyWinner.map((row, index) => (
                        <TableRow
                          key={"winnerDaily" + index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 }, borderBottom: '3px solid #13206D' }}
                        >
                          <TableCell align='center' size='medium' width={'50%'} sx={{ color: '#FFDB00', fontWeight: 'bold' }} >
                            {row.toUpperCase()}
                          </TableCell>
                          <TableCell align="center" size='medium' width={'50%'} sx={{ color: '#FFDB00', fontWeight: 'bold' }}>
                            Premio diario
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={1} md={2}></Grid>
      </Grid>
    </Grid>

  )
}

export { Ranking };
