import React from 'react'
import { Grid, useMediaQuery, Typography } from '@mui/material'
import { ImageContainer } from './RewardsStyles'
import { handleTyC } from '../Footer'

const Rewards = () => {
  const xs = useMediaQuery('(max-width: 899px)');
  const md = useMediaQuery('(min-width: 900px)');
  return (
    <Grid container item spacing={2} direction={'column'} sx={{ marginTop: { xs: 5, md: 10 } }} id={"Premios"}>
      {/* <Typography color={'secondary'} align='center' fontWeight={'bold'} sx={{ fontSize: { xs: 20, md: 42 } }}>Premios</Typography> */}
      <Grid container item spacing={{ xs: 0, md: 6 }} direction={'row'} sx={{ padding: 0 }}>
        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} md={8} display={'flex'} justifyContent={'center'}>
          {xs && (
            <img
              src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/ElementosBack.svg'}
              alt='Premios'
              style={{ width: "100%", position: 'absolute', marginTop: '5vh' }}
            />
          )}
          {md && (
            <img
              src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/ElementosBack.svg'}
              alt='Premios'
              style={{ width: "80%", position: 'absolute', marginTop: '20vh' }}
            />
          )}
          <img
            src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/PremioSemanal.svg'}
            alt='PremioSemanal'
            style={{ width: "75%", zIndex: 1 }}
          />
        </Grid>
        <Grid item xs={0} md={2}></Grid>

        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} md={8} display={'flex'} justifyContent={'center'}>
          <img
            src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/SemanalDinero.svg'}
            alt='SemanalDinero'
            style={{ width: "75%", zIndex: 1 }}
          />
        </Grid>
        <Grid item xs={0} md={2}></Grid>

        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} md={8} display={'flex'} justifyContent={'center'} sx={{ marginTop: { xs: '2.5vh', md: '15vh' } }}>
          <img
            src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/PremiosDiarios.svg'}
            alt='PremiosDiarios'
            style={{ width: "75%", zIndex: 1 }}
          />
        </Grid>
        <Grid item xs={0} md={2}></Grid>

        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} md={8} display={'flex'} justifyContent={'center'}>
          <img
            src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/DiariosDinero.svg'}
            alt='DiarioDinero'
            style={{ width: "75%", zIndex: 1 }}
          />
        </Grid>
        <Grid item xs={0} md={2}></Grid>
      </Grid>

      <Grid container item spacing={2} direction={'row'} sx={{ padding: 0, marginTop: { xs: 0, md: 25 } }}>
        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} md={8} display={'flex'} justifyContent={'center'}>
          <img
            src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/PorRegistro.svg'}
            alt='PremiosPorRegistro'
            style={{ width: "75%", zIndex: 1 }}
          />
        </Grid>
        <Grid item xs={0} md={2}></Grid>
      </Grid>

      <Grid container item spacing={2} direction={'row'} sx={{ padding: 0, marginTop: { xs: -8, md: 0 } }}>
        <Grid item xs={0} md={2}></Grid>
        <Grid container item spacing={4} xs={12} md={8} margin={2} style={{ paddingLeft: 0 }}>
          <Grid item xs={4} md={2}>
            <ImageContainer
              src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Smart.svg'}
              alt='Smart'
              onClick={() => {
                window.open('https://www.smartfit.com.mx/', '_blank');
              }}
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <ImageContainer
              src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/100Natural.svg'}
              alt='Natural100'
              onClick={() => {
                window.open('https://www.100natural.com/', '_blank');
              }}
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <ImageContainer
              src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/SkyZone.svg'}
              alt='SkyZone'
              onClick={() => {
                window.open('https://www.skyzonelindavista.com.mx/', '_blank');
              }}
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <ImageContainer
              src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Recorcholis.svg'}
              alt='Recorcholis'
              onClick={() => {
                window.open('https://recorcholis.com.mx/', '_blank');
              }}
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <ImageContainer
              src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/inTheAir.svg'}
              alt='inTheAir'
              onClick={() => {
                window.open('https://intheair.com.mx/', '_blank');
              }}
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <ImageContainer
              src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/ClickBus.svg'}
              alt='ClickBus'
              onClick={() => {
                window.open('https://www.clickbus.com.mx/', '_blank');
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={0} md={2}></Grid>
      </Grid>

      <Grid item xs={12} display={"flex"} justifyContent={"center"}>
        <Typography color={'white'} fontWeight={'bold'} sx={{ fontSize: { xs: 10, md: 15 } }} component={"span"}>**CONSULTA&nbsp;</Typography>
        <Typography
          color={'white'}
          fontWeight={'bold'}
          sx={{
            fontSize: { xs: 10, md: 15 }, cursor: 'pointer', ":hover": { textDecoration: 'underline' }
          }}
          component={"span"}
          onClick={() => { handleTyC() }}
        >TÉRMINOS Y CONDICIONES</Typography>
        <Typography color={'white'} fontWeight={'bold'} sx={{ fontSize: { xs: 10, md: 15 } }} component={"span"}>&nbsp;PARA REDIMIR LOS PREMIOS</Typography>
        <Typography color={'white'} fontWeight={'bold'} sx={{ fontSize: { xs: 10, md: 15 } }} component={"span"}>.</Typography>
      </Grid>

      <Grid item xs={12} md={12} display={'flex'} justifyContent={'center'} style={{ paddingTop: 0 }}>
        <Typography color={'white'} align='center' fontWeight={'bold'} sx={{ fontSize: { xs: 10, md: 15 }, marginBottom: 5 }}>*Imágenes de referencia</Typography>
      </Grid>
    </Grid >

  )
}

export { Rewards };
