import { useState, useEffect } from 'react';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Cookies from 'js-cookie';


export const useSignup = (handleDisplayRewardModal, setIdUser, idUser, handleDisplayRewardDailyModal, setCuponDaily, setIdBarcode, rewardDaily) => {

  const urlWebApi = 'https://api-landing-9838hd.mywebserver.in/api/v1/'
  const [step, setStep] = useState(1);
  const [giftsList, setGiftsList] = useState([]);
  const [points, setPoints] = useState("");
  const [errorRequest, setErrorRequest] = useState("");
  const [lotameProduct, setLotameProduct] = useState(false);
  const [lotameWhatsapp, setLotameWhatsapp] = useState(false);
  const [successRegister, setSuccessRegister] = useState(false);

  const handleLotameWhatsapp = () => {
    setLotameWhatsapp(true);
    setTimeout(() => {
      setLotameWhatsapp(false);
    }, 5000); // Oculta la imagen después de 5 segundos
  };

  const handleLotameProduct = () => {
    setLotameProduct(true);
    setTimeout(() => {
      setLotameProduct(false);
    }, 5000); // Oculta la imagen después de 5 segundos
  };

  const statesList = [
    { value: 1, label: "Aguascalientes" },
    { value: 2, label: "Baja California" },
    { value: 3, label: "Baja California Sur" },
    { value: 4, label: "Campeche" },
    { value: 5, label: "Coahuila" },
    { value: 6, label: "Colima" },
    { value: 7, label: "Chiapas" },
    { value: 8, label: "Chihuahua" },
    { value: 9, label: "Ciudad de México" },
    { value: 10, label: "Durango" },
    { value: 11, label: "Estado de México" },
    { value: 12, label: "Guanajuato" },
    { value: 13, label: "Guerrero" },
    { value: 14, label: "Hidalgo" },
    { value: 15, label: "Jalisco" },
    { value: 16, label: "Michoacan" },
    { value: 17, label: "Morelos" },
    { value: 18, label: "Nayarit" },
    { value: 19, label: "Nuevo León" },
    { value: 20, label: "Oaxaca" },
    { value: 21, label: "Puebla" },
    { value: 22, label: "Querétaro" },
    { value: 23, label: "Quintana Roo" },
    { value: 24, label: "San Luis Potosí" },
    { value: 25, label: "Sinaloa" },
    { value: 26, label: "Sonora" },
    { value: 27, label: "Tabasco" },
    { value: 28, label: "Tamaulipas" },
    { value: 29, label: "Tlaxcala" },
    { value: 30, label: "Veracruz" },
    { value: 31, label: "Yucatán" },
    { value: 32, label: "Zacatecas" }
  ]
  let productsList = [
    { value: "DANONE", label: "DANONE" },
    { value: "DANUP", label: "DANUP" },
    { value: "DANONINO", label: "DANONINO" },
    { value: "OIKOS", label: "OIKOS" },
    { value: "ACTIVIA", label: "ACTIVIA" },
    { value: "DANETTE", label: "DANETTE" },
    { value: "BEN G", label: "BEN G" },
    { value: "DANMIX", label: "DANMIX" },
    { value: "DANY", label: "DANY" },
    { value: "VITALINEA", label: "VITALINEA" }
  ]

  const initialValues = {
    tyc: false,
    privacy: false,
    isAdult: false
  };

  const initialPhoneValues = {
    phone: ''
  };

  const initialUserValues = {
    name: '',
    lastname: '',
    email: '',
    state: '',
    id: ''
  };

  const initialProductValues = {
    product: '',
    code: '',
    lote: '',
    photo: null
  };

  const initialGiftValues = {
    gift: '',
    points: 0
  };

  const requiredFieldError = "Campo requerido";

  const getValidationRulesInit = () =>
    Yup.object().shape({
      tyc: Yup.boolean().oneOf([true], requiredFieldError),
      privacy: Yup.boolean().oneOf([true], requiredFieldError),
      isAdult: Yup.boolean().oneOf([true], requiredFieldError)
    });

  const getValidationRulesPhone = () =>
    Yup.object().shape({
      phone: Yup.string().required(requiredFieldError)
    });

  const getValidationRulesUser = () =>
    Yup.object().shape({
      name: Yup.string().required(requiredFieldError)
        .matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s']+$/, 'Formato de Nombre inválido'),
      lastname: Yup.string().required(requiredFieldError)
        .matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s']+$/, 'Formato de Apellido inválido'),
      email: Yup.string().required(requiredFieldError)
        .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'Formato de correo electrónico inválido'),
      state: Yup.string().required(requiredFieldError)
        .matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s']+$/, 'Formato de Estado inválido')
    });


  const getValidationRulesProduct = () =>
    Yup.object().shape({
      product: Yup.string().required("Producto es requerido"),
      code: Yup.string().required("Código de barras requerido")
        .matches(/^\d{8,13}$/, 'Código de barras inválido. Escriba entre 8 y 13 dígitos'),
      lote: Yup.string().required("Lote es requerido")
        .matches(/^(0[1-9]|[12][0-9]|3[01]) (ENE|FEB|MAR|ABR|MAY|JUN|JUL|AGO|SEP|OCT|NOV|DIC) ([0-9]{2}) L([0-9]{1,2}) ([01][0-9]|2[0-3]):([0-5][0-9])$/, 'El formato del lote es inválido'),
      photo: Yup.mixed()
        .required('Foto requerida')
        .test('fileSize', 'El archivo es demasiado grande', (value) => {
          if (!value) return true;
          return value.size <= 16 * 1024 * 1024; // 16 MB
        })
        .test('fileType', 'Solo se permiten imágenes', (value) => {
          if (!value) return true;
          return ['image/jpeg', 'image/png', 'image/gif'].includes(value.type);
        })
    });

  const getValidationRulesGift = () =>
    Yup.object().shape({
      gift: Yup.string().required(requiredFieldError)
    });

  const initFormik = useFormik({
    initialValues: initialValues,
    validationSchema: getValidationRulesInit,
    onSubmit: values => {
      setStep(2)
    },
  });

  const phoneFormik = useFormik({
    initialValues: initialPhoneValues,
    validationSchema: getValidationRulesPhone,
    onSubmit: values => {
      handleValidatePhone(values.phone)
    },
  });

  const userFormik = useFormik({
    initialValues: initialUserValues,
    validationSchema: getValidationRulesUser,
    onSubmit: async values => {
      let body = {
        name: values.name,
        lastname: values.lastname,
        state: values.state,
        email: values.email,
        phone: phoneFormik.values.phone
      }
      await handleRegisterUser(body)
    },
  });

  const productFormik = useFormik({
    initialValues: initialProductValues,
    validationSchema: getValidationRulesProduct,
    onSubmit: async values => {
      let formData = new FormData();
      formData.append('code', values.code);
      formData.append('lote', values.lote);
      formData.append('idUser', idUser);
      formData.append('product', values.product);

      // Suponiendo que `values.image` es el archivo de imagen que deseas subir
      formData.append('image', values.photo);

      handleRegisterProduct(formData)
    },
  });

  const giftFormik = useFormik({
    initialValues: initialGiftValues,
    validationSchema: getValidationRulesGift,
    onSubmit: async values => {
      setPoints('¡Felicidades, ganaste un beneficio de uno de nuestros aliados! Sigue acumulando puntos para ganar un premio semanal de hasta $50,000 pesos.\nTienes acumulados: ' + values.points + ' puntos')
      let giftSelected = giftsList.find(x => x.value == values.gift)
      try {
        const response = await axios.get(giftSelected.url, { responseType: 'arraybuffer' });
        const blob = new Blob([response.data], { type: 'image/png' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'cupon.png';
        a.click();
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error(error);
      }
    },
  });


  const handleValidatePhone = (phone) => {
    let body = {
      phone: phone
    }

    axios.post(urlWebApi + 'auth/validate/phone', body)
      .then((response) => {
        const { success, register, data } = response.data

        const cookiesRemember = Cookies.get('cookies');
        if (cookiesRemember) {
          if (register) {
            Cookies.set('user', JSON.stringify({ id: data.id, phone: data.phone }), { expires: 7 });
            setIdUser(data.id)
            setStep(4)
          } else {
            setStep(3)
          }
        } else {
          if (register) {
            setIdUser(data.id)
            setStep(4)
          } else {
            setStep(3)
          }
        }

      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleRegisterUser = (body) => {
    axios.post(urlWebApi + 'auth/register', body)
      .then((response) => {
        const { success, register, data } = response.data
        const cookiesRemember = Cookies.get('cookies');
        if (cookiesRemember) {
          Cookies.set('user', JSON.stringify({ id: data.id, phone: data.phone }), { expires: 7 });
          userFormik.setFieldValue('id', data.id)
          setIdUser(data.id)
          setStep(4)
        } else {
          setIdUser(data.id)
          setStep(4)
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleRegisterProduct = (formdata) => {
    setSuccessRegister(true)
    axios.post(urlWebApi + 'barcode/register', formdata, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((response) => {
        const { success, data, cuponUrl, idGift, totalPoints, message } = response.data
        if (success) {
          window.triggerTeadsEvent(productFormik.values.product);
          handleLotameProduct()
          setPoints('¡Felicidades, ganaste un beneficio de uno de nuestros aliados! Sigue acumulando puntos para ganar un premio semanal de hasta $50,000 pesos.\nTienes acumulados: ' + totalPoints + ' puntos')
          setStep(5)
          // setGiftsList(gifts)
          giftFormik.setFieldValue('points', totalPoints)
          productFormik.resetForm();
          if (idGift != 0) {
            handleDisplayRewardDailyModal(true)
            setCuponDaily(cuponUrl)
          } else {
            handleDisplayRewardModal(true)
            setIdBarcode(data.id)
          }
        } else {
          setStep(4)
          setErrorRequest(message)
        }

      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setSuccessRegister(false)
      });
  }

  useEffect(() => {
    const value = Cookies.get('user');
    if (value) {
      let userData = JSON.parse(value)
      setIdUser(userData.id)
      setStep(4)
    }
  }, []);

  useEffect(() => {
    if (idUser != 0) {
      axios.get(urlWebApi + 'auth/users/' + idUser + '/points')
        .then((response) => {
          const { success, totalPoints } = response.data
          setPoints('¡Felicidades, ganaste un beneficio de uno de nuestros aliados! Sigue acumulando puntos para ganar un premio semanal de hasta $50,000 pesos.\nTienes acumulados: ' + totalPoints + ' puntos')
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [rewardDaily]);
  console.log(productFormik.errors)

  return {
    initFormik,
    phoneFormik,
    userFormik,
    productFormik,
    giftFormik,
    statesList,
    productsList,
    step,
    setStep,
    giftsList,
    points,
    setPoints,
    errorRequest,
    setErrorRequest,
    lotameProduct,
    lotameWhatsapp,
    handleLotameProduct,
    handleLotameWhatsapp,
    successRegister
  }
}