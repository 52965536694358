import React, { useState } from 'react'
import { handleTyC, handlePreguntas, handlePrivacidad } from '../Footer'
import { Box, List, ListItem, ListItemButton, ListItemText, AppBar, Toolbar, IconButton, Button, Drawer, useScrollTrigger, ButtonGroup } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { Close } from '@mui/icons-material';

const handledRedirect = (div) => {
  document.querySelector(`#${div.replace(' ', '')}`).scrollIntoView({
    behavior: 'smooth'
  });
};

const ElevationScroll = (props) => {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}


const Menu = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  // const classes = useStyles();

  const drawerWidth = 240;
  const navItems = ['Participa', 'Premios', 'Productos participantes', 'Ganadores', 'Términos y condiciones', 'Preguntas frecuentes', 'Aviso de privacidad'];

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton
              sx={{ textAlign: 'left' }}
              onClick={() => {
                if ('Términos y condiciones' == item) {
                  handleTyC()
                } else if ('Preguntas frecuentes' == item) {
                  handlePreguntas()
                } else if ('Aviso de privacidad' == item) {
                  handlePrivacidad()
                } else {
                  handledRedirect(item)
                }
              }}
            >
              <ListItemText primary={item} primaryTypographyProps={{ fontWeight: 'bold' }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <ElevationScroll {...props}>
        <AppBar component="nav" sx={{ backgroundColor: { xs: '#003387', md: 'transparent' }, alignItems: { xs: "flex-start", md: "center" }, boxShadow: "none" }}>
          <Toolbar sx={{ width: { xs: '100%', md: 'auto' } }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              size='large'
              sx={{ display: { md: 'none' }, fontSize: '2.5rem', padding: 0 }}
            >
              <MenuIcon fontSize='2.5rem' />
            </IconButton>
            <Box sx={{ width: { xs: '100%', md: 'auto' }, display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: { xs: '-2.5rem', md: 0 } }}>
            <img
                src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/logo_danone.svg'}
              alt="DanoneLogo"
            />
            </Box>
            <ButtonGroup size="small" variant="text" sx={{ display: { xs: 'none', md: 'block' }, backgroundColor: "white", ml: 2, borderRadius: 5 }}>
              {navItems.map((item) => (
                <Button key={item} sx={{ borderColor: "white", borderWidth: 5 }}
                  onClick={() => {
                    if ('Términos y condiciones' == item) {
                      handleTyC()
                    } else if ('Preguntas frecuentes' == item) {
                      handlePreguntas()
                    } else if ('Aviso de privacidad' == item) {
                      handlePrivacidad()
                    } else {
                      handledRedirect(item)
                    }
                  }}
                >
                  {item}
                </Button>
              ))}
            </ButtonGroup>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
          <IconButton color="inherit"
            sx={{ position: "absolute", top: 0, right: 0, zIndex: 10 }}
            onClick={handleDrawerToggle}
          >
            <Close
              sx={{ fontSize: 30 }}
            />
          </IconButton>
        </Drawer>
      </nav>
    </Box>

  )
}

export { Menu };
