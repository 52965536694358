import React, { useState } from "react";
import { Box, Grid, IconButton, Modal, Typography, useMediaQuery } from '@mui/material'
import { Close } from '@mui/icons-material';
import styled from "@emotion/styled";
import axios from 'axios';


const FondoCuponStyled = styled(Box)
  (({ theme }) => ({
    minHeight: '100vh',
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center'
  }));

const FondoStyled = styled(Box)
  (({ theme }) => ({
    backgroundImage: `url('https://danone50anos-cdn.mywebserver.in/assets2/svg/confetis_head.svg')`,
    backgroundRepeat: 'repeat',
    backgroundSize: 'cover',
    minHeight: '100vh',
    backgroundColor: '#003387',
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center'
  }));

const CardStyled = styled(Box)
  (({ theme }) => ({
    backgroundColor: "white",
    height: "100%",
    width: "100%",
    overflow: 'hidden',
    position: 'relative',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.2)',
      cursor: "pointer"
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

const ImageContainer = styled('img')(({ theme }) => ({
  width: '75%',
  height: '75%'
}));


const RewardModal = ({ display, setDisplay, idUser, idBarcode }) => {
  const urlWebApi = 'https://api-landing-9838hd.mywebserver.in/api/v1/'
  const [displayCupon, setDisplayCupon] = useState(false);
  const [urlCupon, setUrlCupon] = useState('');

  const handleRedeemGift = (id) => {
    let body = {
      idGift: id,
      idUser: idUser,
      idBarcode: idBarcode
    }

    axios.post(urlWebApi + 'gifts/redeem', body)
      .then((response) => {
        const { success, register, data } = response.data
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const downloadCupon = (imageUrl, id) => {
    handleRedeemGift(id)
    setUrlCupon(imageUrl)
    setDisplayCupon(true);
    // Crear un enlace de descarga
    var link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'cupon.png'; // Nombre del archivo que se descargará

    // Añadir el enlace al documento y hacer clic en él
    document.body.appendChild(link);
    link.click();

    // Remover el enlace del documento
    document.body.removeChild(link);
  }

  const xs = useMediaQuery('(max-width: 899px)');
  const md = useMediaQuery('(min-width: 900px)');

  return (
    <Modal
      open={display}
    >
      {displayCupon ?
        <FondoCuponStyled>
          <IconButton style={{ color: 'white' }}
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => {
              setDisplay(false)
              setDisplayCupon(false)
              setUrlCupon("")
            }}
          >
            <Close
              sx={{ fontSize: 72 }}
            />
          </IconButton>
          {xs && (
            <img
              src={urlCupon}
              alt='cupon'
              width={'90%'}
              style={{
                boxShadow: '0 0 20px 10px rgba(255, 255, 255, 0.5)'
              }}
            />
          )}
          {md && (
            <img
              src={urlCupon}
              alt='cupon'
              style={{
                boxShadow: '0 0 20px 10px rgba(255, 255, 255, 0.5)'
              }}
            />
          )}
        </FondoCuponStyled>
        :
        <FondoStyled>
          <Typography color={"white"} align="center" fontWeight={'bold'} sx={{ fontSize: { xs: 36, md: 72 }, marginBottom: 10 }}>
            ¡ELIGE TU PREMIO!
          </Typography>

          <Grid container item spacing={2}>
            <Grid item xs={2}></Grid>
            <Grid container item spacing={4} xs={8} direction={'row'}>
              <Grid item xs={6} md={4}>
                <CardStyled>
                  <ImageContainer
                    src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Aliados/Smart.png'}
                    alt='Smart'
                    onClick={() => {
                      downloadCupon('./cupones/ALIADOS/CUPONES-500x500-smart-fit.png', 90006)
                    }}
                  />
                </CardStyled>
              </Grid>
              <Grid item xs={6} md={4}>
                <CardStyled>
                  <ImageContainer
                    src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Aliados/Natural.jpg'}
                    alt='Natural100'
                    onClick={() => {
                      downloadCupon('./cupones/ALIADOS/CUPONES-500x500-100.png', 90004)
                    }}
                  />
                </CardStyled>
              </Grid>
              <Grid item xs={6} md={4}>
                <CardStyled>
                  <ImageContainer
                    src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Aliados/SkyZone.png'}
                    alt='SkyZone'
                    onClick={() => {
                      downloadCupon('./cupones/ALIADOS/CUPONES-500x500-sky-z.png', 90002)
                    }}
                  />
                </CardStyled>
              </Grid>

              <Grid item xs={6} md={4}>
                <CardStyled>
                  <ImageContainer
                    src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Aliados/recorcholis.png'}
                    alt='recorcholis'
                    onClick={() => {
                      downloadCupon('./cupones/ALIADOS/CUPONES-500x500-recorch.png', 90003)
                    }}
                  />
                </CardStyled>
              </Grid>
              <Grid item xs={6} md={4}>
                <CardStyled>
                  <ImageContainer
                    src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Aliados/intheair.png'}
                    alt='intheair'
                    onClick={() => {
                      downloadCupon('./cupones/ALIADOS/CUPONES-500x500-in-the.png', 90001)
                    }}
                  />
                </CardStyled>
              </Grid>
              <Grid item xs={6} md={4}>
                <CardStyled>
                  <ImageContainer
                    src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Aliados/clicbus.jpg'}
                    alt='Clickbus'
                    onClick={() => {
                      downloadCupon('/cupones/ALIADOS/CUPONES-500x500-click-bus.png', 90005)
                    }}
                  />
                </CardStyled>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}></Grid>

        </FondoStyled>
      }
    </Modal >
  )
}

export { RewardModal };
