import React from 'react'
import { Grid, useMediaQuery } from '@mui/material'

const CTA = () => {
  const xs = useMediaQuery('(max-width: 899px)');
  const md = useMediaQuery('(min-width: 900px)');
  return (
    <Grid container item spacing={2} sx={{ marginTop: { xs: 0, md: 10 } }}>
      <Grid item xs={0} md={2}></Grid>
      <Grid item xs={12} md={4} display={"flex"} justifyContent={"center"} >
        <Grid item xs={2} md={0}></Grid>
        <Grid item xs={12} md={12} display={"flex"} justifyContent={"center"} >
          <img
            src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/productos_CTA.svg'}
            alt="ProductosCTA"
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={2} md={0}></Grid>
      </Grid>
      <Grid item xs={12} md={4} display={"flex"} justifyContent={"center"} >
        <Grid item xs={2} md={0}></Grid>
        <Grid item xs={12} md={12} display={"flex"} justifyContent={"center"} >

          {xs && (
            <img
              src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/50anios_CTAMOBILEYE.svg'}
              alt="Anios50CTA"
              style={{ width: "100%" }}
            />
          )}
          {md && (
            <img
              src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/50anios_CTA.svg'}
            alt="Anios50CTA"
            style={{ width: "100%" }}
          />
          )}
        </Grid>
        <Grid item xs={2} md={0}></Grid>
      </Grid>
      <Grid item xs={0} md={2}></Grid>
    </Grid>

  )
}

export { CTA };
