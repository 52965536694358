import React, { useEffect } from 'react';
import { Images } from '../src/assets';
import { Landing } from './Views/Landing';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#003387',
    },
    secondary: {
      main: '#FFDB00',
    },
    grey: {
      main: '#545454'
    },
    text: {
      primary: '#003387',
      secondary: '#FFDB00'
    }
  },
});

function App() {
  useEffect(() => {
    const link = document.querySelector("link[rel='icon']");
    if (link) {
      link.href = Images.Favicon;
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Landing />
    </ThemeProvider>
  );
}

export default App;
