import React, { useState } from 'react'
import { Grid, Button, Typography, Divider, ButtonGroup } from '@mui/material'

const handleTyC = () => {
  const pdfUrl = './docs/TerminosYCondiciones.pdf';
  window.open(pdfUrl, '_blank');
};
const handlePrivacidad = () => {
  const pdfUrl = './docs/AvisoPrivacidad.pdf';
  window.open(pdfUrl, '_blank');
};
const handlePreguntas = () => {
  const pdfUrl = './docs/PreguntasFrecuentes.pdf';
  window.open(pdfUrl, '_blank');
};


const Footer = () => {
  return (
    <Grid container item spacing={2} direction={'column'} sx={{ marginTop: { xs: 5, md: 10 }, backgroundColor: '#D9D9D9', paddingBottom: 5 }}>
      <Grid container item spacing={2} direction={'row'} sx={{ padding: 0, marginTop: { xs: -5, md: 2 }, marginBottom: { xs: 0, md: 5 } }}>
        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} md={8} display={'flex'} justifyContent={'center'}>
          <ButtonGroup variant="text" aria-label="tyc">
            <Button size='small' sx={{ fontWeight: 'bold', fontSize: { xs: 10, md: 14 } }} onClick={() => { handleTyC() }}>TÉRMINOS Y CONDICIONES</Button>
            <Button size='small' sx={{ fontWeight: 'bold', fontSize: { xs: 10, md: 14 } }} onClick={() => { handlePrivacidad() }}>AVISO DE PRIVACIDAD</Button>
            <Button size='small' sx={{ fontWeight: 'bold', fontSize: { xs: 10, md: 14 } }} onClick={() => { handlePreguntas() }}>PREGUNTAS FRECUENTES</Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={0} md={2}></Grid>
      </Grid>

      <Divider orientation="horizontal" sx={{ borderColor: '#003387', borderWidth: 5, borderStyle: 'solid' }} />
      <Divider orientation="horizontal" sx={{ borderColor: 'transparent', borderWidth: 2, borderStyle: 'solid' }} />
      <Divider orientation="horizontal" sx={{ borderColor: '#003387', borderWidth: 5, borderStyle: 'solid' }} />

      <Grid container item spacing={2} direction={'row'} sx={{ padding: 0 }}>
        <Grid item xs={1} md={2}></Grid>
        <Grid item xs={10} md={8} display={'flex'} justifyContent={'space-between'}>
          <Typography color={'primary'} sx={{ fontSize: { xs: 10, md: 14 } }}>
            COME BIEN
          </Typography>
          <Typography color={'primary'} sx={{ fontSize: { xs: 10, md: 14 } }}>
            Revisa términos y condiciones
          </Typography>
        </Grid>
        <Grid item xs={1} md={2}></Grid>
      </Grid>
      <Grid container item spacing={2} direction={'row'} sx={{ padding: 0 }}>
        <Grid item xs={1} md={2}></Grid>
        <Grid item xs={10} md={8}>
          <Typography color={'primary'} sx={{ fontSize: { xs: 10, md: 14 } }}>
            RESPONSABLE DE LA PROMOCIÓN FUTURE GROUP DEVELOPERS, S.A. DE C.V., VIGENCIA DE LA PROMOCIÓN DEL 10 DE AGOSTO AL 10 DE OCTUBRE DE 2024. CONSULTA LA MECÁNICA, TÉRMINOS Y CONDICIONES, RESTRICCIONES, PREMIOS, CANTIDAD, COBERTURA GEOGRÁFICA Y AVISO DE PRIVACIDAD EN: celebra50aniversario.danone.com.mx
          </Typography>
        </Grid>
        <Grid item xs={1} md={2}></Grid>
      </Grid>
    </Grid >


  )
}

export { Footer, handlePreguntas, handleTyC, handlePrivacidad };
