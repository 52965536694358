import React, { useRef } from 'react'
import { Grid, Typography, IconButton } from '@mui/material'
import { KeyboardDoubleArrowRight, KeyboardDoubleArrowLeft } from '@mui/icons-material';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import { handleTyC } from '../Footer'
import { ImageContainer } from './ProductsStyles'

var items = [
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/2.png'} alt='item1' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/4x84g uva acostado.png'} alt='item2' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/90g uva CON LEGALES.png'} alt='item3' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/170g manzana CON LEGALES.png'} alt='item4' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/10513043-TAPA-SMASH-QUAKER-PLATANO-CANELA-CON-AVENA-650.png'} alt='item5' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/10513044-TAPA-SMASH-QUAKER-FRUTOS-ROJOS-CON-AVENA-650.png'} alt='item6' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/7501032300036 QUETZAL GRIEGO SIN ENDULZAR.png'} alt='item7' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/7501032300043 QUETZAL GRIEGO ENDULZADO.png'} alt='item8' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/7506443100343 WENDY FRESA MORA 8 PACK.png'} alt='item9' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/7506443107663 120g PARRITO ENDULZADO.png'} alt='item10' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/7506443107670 120g PARRITO SIN AZUCAR.png'} alt='item11' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/7506443108028 PARRICOS ENDULZADO.png'} alt='item12' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/7506443108035 PARRICOS SIN ENDULZAR.png'} alt='item13' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/7506443108042-WENDY-DESLACTOSADO-FRESA-220.png'} alt='item14' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/7506443108059-WENDY-DESLACTOSADO-FRESA-KIWI-220.png'} alt='item15' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/7506443108066-WENDY-DESLACTOSADO-FRUTOS-VERDES-220.png'} alt='item16' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/7506443108158 FRONT.png'} alt='item17' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/7506443108165FRONT.png'} alt='item18' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/7506443108202-SMASH-435-FRESA.png'} alt='item19' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/7506443108219-SMASH-PLATANO-CANELA-435.png'} alt='item20' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/7506443108226-SMASH-PLATANO-CANELA-CON-AVENA-650.png'} alt='item21' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/7506443108233-SMASH-FRUTOS-ROJOS-650.png'} alt='item22' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/7506443108226-SMASH-PLATANO-CANELA-CON-AVENA-650.png'} alt='item23' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/7506443108233-SMASH-FRUTOS-ROJOS-650.png'} alt='item24' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/7506443108295-GREMLIN-KIDS-MALTEADA-VAINILLA-220.png'} alt='item25' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/7506443108301-GREMLIN-KIDS-MALTEADA-FRESA-220.png'} alt='item26' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/DANMIX-110-MM-preciado-20-pesos.png'} alt='item27' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/Pantera Rosa-SLIM CORE 125-v4.png'} alt='item28' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/Pantera Rosa-SLIM CORE 150-Pote 150g.png'} alt='item29' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/Pantera-Rosa-SLIM-CORE-700_v4.png'} alt='item30' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/PARRITO FRUTADO DURAZNO RENDER.png'} alt='item31' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/PARRITO FRUTADO FRESA RENDER.png'} alt='item32' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/PARROTE 900G GRIEGO ENDULZADO copy.png'} alt='item33' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/PARROTE 900G GRIEGO SIN AZUCAR copy.png'} alt='item34' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/pouch UVA.png'} alt='item35' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/PRESS_PANTERA ROSA PRE-APROBADA-v6.png'} alt='item36' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/RENDER_MW_1200.png'} alt='item37' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/RENDER-BOOSTER PREGHOST COCO MANGO.png'} alt='item38' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/RENDER-BOOSTER PREGHOST FRUTOS ROJOS.png'} alt='item39' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />,
  <img src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Productos/uva 170g CON LEGALES.png'} alt='item40' style={{ maxWidth: '100%', maxHeight: '15vh', margin: 'auto' }} />
]

const renderItems = () => {
  let images = items.map((item, index) => (
    <div key={index} style={{ width: '10rem', height: '10rem', margin: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
      {item}
    </div >
  ));

  return images;
};


const Products = () => {
  const carousel = useRef(null);

  return (
    <Grid container item spacing={2} direction={'column'} sx={{ marginTop: { xs: 5, md: 10 } }} id='Productosparticipantes'>
      <Grid container item spacing={2} direction={'row'} sx={{ padding: 0, marginTop: { xs: -5, md: 0 } }}>
        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} md={8} display={'flex'} justifyContent={'center'}>
          <img
            src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/ProductosParticipantes.svg'}
            alt='ProductosParticipantes'
            style={{ width: "75%" }}
          />
        </Grid>
        <Grid item xs={0} md={2}></Grid>
      </Grid>

      <Grid container item spacing={2} direction={'row'} sx={{ padding: 0, marginTop: { xs: -5, md: 0 } }}>
        <Grid item xs={0} md={1}></Grid>
        <Grid item xs={12} md={10} display={'flex'} justifyContent={'center'}>
          <Grid container item spacing={2} direction={'row'} sx={{ padding: 0 }}>
            <Grid item xs={1} md={1}></Grid>
            <Grid item xs={2} md={1}>
              <ImageContainer
                src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Danone.svg'}
                alt='Danone'
                onClick={() => {
                  window.open('https://www.danone.com.mx/', '_blank');
                }}
              />
            </Grid>
            <Grid item xs={2} md={1}>
              <ImageContainer
                src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/DNP.svg'}
                alt='DNP'
                onClick={() => {
                  window.open('https://danup.com.mx/', '_blank');
                }}
              />
            </Grid>
            <Grid item xs={2} md={1}>
              <ImageContainer
                src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Danonino.svg'}
                alt='Danonino'
                onClick={() => {
                  window.open('https://www.danonino.com.mx/', '_blank');
                }}
              />
            </Grid>
            <Grid item xs={2} md={1}>
              <ImageContainer
                src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Oikos.svg'}
                alt='Oikos'
                onClick={() => {
                  window.open('https://www.oikosmexico.com.mx/', '_blank');
                }}
              />
            </Grid>
            <Grid item xs={2} md={1}>
              <ImageContainer
                src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Activia.svg'}
                alt='Activia'
                onClick={() => {
                  window.open('https://www.activia.com.mx/', '_blank');
                }}
              />
            </Grid>

            <Grid item xs={1} display={{ xs: 'block', md: 'none' }}></Grid>
            <Grid item xs={1} display={{ xs: 'block', md: 'none' }}></Grid>
            <Grid item xs={2} md={1}>
              <ImageContainer
                src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Danette.svg'}
                alt='Danette'
                onClick={() => {
                  window.open('https://www.danette.com.mx/', '_blank');
                }}
              />
            </Grid>
            <Grid item xs={2} md={1}>
              <ImageContainer
                src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Dany.svg'}
                alt='Dany'
                onClick={() => {
                  window.open('https://danymexico.com.mx/', '_blank');
                }}
              />
            </Grid>
            <Grid item xs={2} md={1}>
              <ImageContainer
                src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Danmix@2x.svg'}
                alt='Danmix'
                onClick={() => {
                  window.open('https://www.grupodanone.com.mx/marcas/danone/danmix.aspx', '_blank');
                }}
              />
            </Grid>
            <Grid item xs={2} md={1}>
              <ImageContainer
                src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/BeneG.svg'}
                alt='BeneG'
                onClick={() => {
                  window.open('https://www.grupodanone.com.mx/marcas/danone/bene-g.aspx', '_blank');
                }}
              />
            </Grid>
            <Grid item xs={2} md={1}>
              <ImageContainer
                src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Vitalinea.svg'}
                alt='Vitalinea'
                onClick={() => {
                  window.open('https://www.vitalinea.com.mx/', '_blank');
                }}
              />
            </Grid>
            <Grid item xs={1} md={1}></Grid>
          </Grid>
        </Grid>
        <Grid item xs={0} md={1}></Grid>
      </Grid>

      <Grid container item spacing={2} direction={'row'} sx={{ padding: 0, marginTop: { xs: -6, md: 0 } }}>
        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} md={8} display={'flex'} justifyContent={'center'}>
          <img
            src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/RememberProducts.svg'}
            alt='RememberProducts'
            style={{ width: "75%" }}
          />
        </Grid>
        <Grid item xs={0} md={2}></Grid>
      </Grid>

      <Grid container item spacing={2} direction={'row'} sx={{ padding: 0, marginTop: { xs: -2, md: 0 } }}>
        <Grid item xs={1} md={2}></Grid>

        <Grid
          item
          xs={10}
          md={8}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ borderColor: '#C4E7F9', borderStyle: 'solid', bordderWidth: 4, borderRadius: 15, backgroundColor: 'rgba(196,231,249,0.5)' }}
          style={{ paddingLeft: '5rem', paddingRight: '5rem' }}>


          <IconButton
            color='secondary'
            onClick={(e) => {
              carousel?.current?.slidePrev(e)
            }}
          >
            <KeyboardDoubleArrowLeft sx={{ fontSize: 72, zIndex: 10 }} />
          </IconButton>

          <AliceCarousel
            autoWidth
            autoHeight
            infinite
            disableDotsControls
            disableButtonsControls
            items={renderItems()}
            ref={carousel}
          />

          <IconButton
            color='secondary'
            onClick={(e) => {
              carousel?.current?.slideNext(e)
            }}
          >
            <KeyboardDoubleArrowRight sx={{ fontSize: 72, zIndex: 10 }} />
          </IconButton>


        </Grid>
        <Grid item xs={1} md={2}></Grid>
      </Grid>

      <Grid container item spacing={2} direction={'row'} sx={{ padding: 0, marginTop: { xs: -5, md: 0 } }}>
        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} md={8} display={'flex'} justifyContent={'center'}>
          <img
            src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Conocelos.svg'}
            alt='Conocelos'
            style={{ width: "50%" }}
          />
        </Grid>
        <Grid item xs={0} md={2}></Grid>
      </Grid>

      <Grid item xs={12} textAlign={'center'} sx={{ marginBottom: 5 }}>
        <Typography color={'white'} fontWeight={'bold'} sx={{ fontSize: { xs: 10, md: 15 } }} component={"span"}>Consulta  listado completo en los&nbsp;</Typography>
        <Typography
          color={'white'}
          fontWeight={'bold'}
          sx={{
            fontSize: { xs: 10, md: 15 }, cursor: 'pointer', ":hover": { textDecoration: 'underline' }
          }}
          component={"span"}
          onClick={() => { handleTyC() }}
        >términos y condiciones</Typography>
        <Typography color={'white'} fontWeight={'bold'} sx={{ fontSize: { xs: 10, md: 15 } }} component={"span"}>&nbsp;en celebra50aniversario.danone.com.mx.</Typography>
      </Grid>
    </Grid >

  )
}

export { Products };
