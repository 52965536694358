import React from 'react'
import { Grid, Card, CardHeader, CardContent, FormControlLabel, Autocomplete, useMediaQuery, Box } from '@mui/material'
import { useSignup } from './useSignup'
import { TextFieldForm, CheckboxForm, TypographyForm, ButtonForm, ImageContainer, ImageWhats, ImageWhatsMobile } from './SignupStyles'
import { Image } from '@mui/icons-material';


const Signup = ({ setDisplay, setReward, setIdUser, idUser, setDisplayDaily, rewardDaily, setCuponDaily, setIdBarcode }) => {
  const {
    initFormik,
    phoneFormik,
    userFormik,
    productFormik,
    giftFormik,
    statesList,
    productsList,
    step,
    setStep,
    giftsList,
    points,
    setPoints,
    errorRequest,
    setErrorRequest,
    lotameProduct,
    lotameWhatsapp,
    handleLotameProduct,
    handleLotameWhatsapp,
    successRegister
  } = useSignup(setReward, setIdUser, idUser, setDisplayDaily, setCuponDaily, setIdBarcode, rewardDaily)

  const xs = useMediaQuery('(max-width: 899px)');
  const md = useMediaQuery('(min-width: 900px)');

  const initForm = () => {
    return (
      <Grid container spacing={2}>

        <Grid item xs={1} lg={1}></Grid>
        <Grid item xs={10} lg={10}>
          <FormControlLabel
            control={<CheckboxForm size='large' value={initFormik.values.isAdult} onChange={(e) => { initFormik.setFieldValue("isAdult", !initFormik.values.isAdult) }} />}
            label={<TypographyForm sx={{
              fontSize: { xs: '1rem', md: '1.2rem', lg: '1.2rem', xl: '1.6rem' }
            }}>Acepto Términos y Condiciones</TypographyForm>} />
        </Grid>
        <Grid item xs={1} lg={1}></Grid>
        {initFormik.errors.isAdult && initFormik.touched.isAdult &&
          <>
          <Grid item xs={1} lg={1}></Grid>
          <Grid item xs={10} lg={10}>
            <TypographyForm color={'error'}>{initFormik.errors.isAdult}</TypographyForm>
            </Grid>
          <Grid item xs={1} lg={1}></Grid>
          </>
        }

        <Grid item xs={1} lg={1}></Grid>
        <Grid item xs={10} lg={10} >
          <FormControlLabel
            control={<CheckboxForm size='large' value={initFormik.values.privacy} onChange={(e) => { initFormik.setFieldValue("privacy", !initFormik.values.privacy) }} />}
            label={<TypographyForm sx={{
              fontSize: { xs: '1rem', md: '1.2rem', lg: '1.2rem', xl: '1.6rem' }
            }}>Acepto Aviso de Privacidad</TypographyForm>} />
        </Grid>
        <Grid item xs={1} lg={1}></Grid>
        {initFormik.errors.privacy && initFormik.touched.privacy &&
          <>
          <Grid item xs={1} lg={1}></Grid>
          <Grid item xs={10} lg={10}>
            <TypographyForm color={'error'}>{initFormik.errors.privacy}</TypographyForm>
            </Grid>
          <Grid item xs={1} lg={1}></Grid>
          </>
        }

        <Grid item xs={1} lg={1}></Grid>
        <Grid item xs={10} lg={10}>
          <FormControlLabel
            control={<CheckboxForm size='large' value={initFormik.values.tyc} onChange={(e) => { initFormik.setFieldValue("tyc", !initFormik.values.tyc) }} />}
            label={<TypographyForm sx={{
              fontSize: { xs: '1rem', md: '1.2rem', lg: '1.2rem', xl: '1.6rem' }
            }}>Soy mayor de edad</TypographyForm>}
          />
        </Grid>
        <Grid item xs={1} lg={1}></Grid>
        {initFormik.errors.tyc && initFormik.touched.tyc &&
          <>
          <Grid item xs={1} lg={1}></Grid>
          <Grid item xs={10} lg={10}>
            <TypographyForm color={'error'}>{initFormik.errors.tyc}</TypographyForm>
            </Grid>
          <Grid item xs={1} lg={1}></Grid>
          </>
        }


        <Grid container item xs={12} justifyContent={'center'}>
          <ButtonForm variant='contained' color='secondary' onClick={() => { initFormik.submitForm() }}>Aceptar</ButtonForm>
        </Grid>
      </Grid >
    )
  };

  const phoneForm = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextFieldForm
            fullWidth
            type='tel'
            label="Celular"
            placeholder='777-777-7777'
            inputProps={{
              maxLength: 10
            }}
            value={phoneFormik.values.phone}
            onChange={(e) => { phoneFormik.setFieldValue("phone", e.target.value) }}
            error={phoneFormik.touched.phone && phoneFormik.errors.phone}
            helperText={phoneFormik.errors.phone}
          />
        </Grid>
        <Grid container item xs={12} justifyContent={'center'}>
          <ButtonForm variant='contained' color='secondary' onClick={() => { phoneFormik.submitForm() }}>Aceptar</ButtonForm>
        </Grid>
      </Grid>
    )
  };


  const userForm = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextFieldForm
            fullWidth
            type='text'
            label="Nombre"
            placeholder='Juan'
            value={userFormik.values.name}
            onChange={(e) => { userFormik.setFieldValue("name", e.target.value) }}
            error={userFormik.touched.name && userFormik.errors.name}
            helperText={userFormik.errors.name}
            sx={{ marginTop: 1, marginBottom: 1 }}
          />
          <TextFieldForm
            fullWidth
            type='text'
            label="Apellido"
            placeholder='Perez'
            value={userFormik.values.lastname}
            onChange={(e) => { userFormik.setFieldValue("lastname", e.target.value) }}
            error={userFormik.touched.lastname && userFormik.errors.lastname}
            helperText={userFormik.errors.lastname}
            sx={{ marginTop: 1, marginBottom: 1 }}
          />
          <TextFieldForm
            fullWidth
            type='email'
            label="Correo electrónico"
            placeholder='juanperez@mail.com'
            value={userFormik.values.email}
            onChange={(e) => { userFormik.setFieldValue("email", e.target.value) }}
            error={userFormik.touched.email && userFormik.errors.email}
            helperText={userFormik.errors.email}
            sx={{ marginTop: 1, marginBottom: 1 }}
          />
          <Autocomplete
            freeSolo
            disableClearable
            options={statesList}
            getOptionLabel={(option) => typeof option === 'string' ? option : option.label}
            renderInput={(params) => (
              <TextFieldForm
                {...params}
                label="Estado"
                InputProps={{
                  ...params.InputProps
                }}
                error={userFormik.touched.state && userFormik.errors.state}
                helperText={userFormik.errors.state}
              />
            )}
            value={userFormik.values.state}
            onChange={(event, newValue) => {
              userFormik.setFieldValue("state", newValue ? newValue.label : "");
            }}
            sx={{ marginTop: 1, marginBottom: 1 }}
          />
        </Grid>
        <Grid container item xs={12} justifyContent={'center'}>
          <ButtonForm variant='contained' color='secondary' onClick={() => { userFormik.submitForm() }}>Registrar</ButtonForm>
        </Grid>
      </Grid>
    )
  };

  const handleFileChange = (event) => {
    console.log(event.target.files[0])
    productFormik.setFieldValue("photo", event.target.files[0])
    let file = event.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onloadend = () => {
          productFormik.setFieldValue('photoSelected', reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        productFormik.setFieldValue('photoSelected', null);
      }
    }
  };

  const productForm = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            freeSolo
            disableClearable
            options={productsList}
            getOptionLabel={(option) => typeof option === 'string' ? option : option.label}
            renderInput={(params) => (
              <TextFieldForm
                {...params}
                label="Producto"
                InputProps={{
                  ...params.InputProps
                }}
                error={productFormik.touched.product && productFormik.errors.product}
                helperText={productFormik.errors.product}
              />
            )}
            value={productFormik.values.product}
            onChange={(event, newValue) => {
              productFormik.setFieldValue("product", newValue ? newValue.label : "");
            }}
            sx={{ marginTop: 1, marginBottom: 1 }}
          />
          <TextFieldForm
            fullWidth
            type='number'
            label="Código de barras"
            placeholder='7501932318514'
            inputProps={{
              maxLength: 13
            }}
            value={productFormik.values.code}
            onChange={(e) => { productFormik.setFieldValue("code", e.target.value) }}
            error={productFormik.touched.code && productFormik.errors.code}
            helperText={productFormik.errors.code}
            sx={{ marginTop: 1, marginBottom: 1 }}
          />
          <TextFieldForm
            fullWidth
            type='text'
            label="Lote"
            placeholder='24 JUN 24 L11 15:56'
            value={productFormik.values.lote}
            onChange={(e) => { productFormik.setFieldValue("lote", e.target.value) }}
            error={productFormik.touched.lote && productFormik.errors.lote}
            helperText={productFormik.errors.lote}
            sx={{ marginTop: 1, marginBottom: 1 }}
          />
        </Grid>
        {
          errorRequest != '' &&
          <Grid item xs={12} marginLeft={1}>
            <TypographyForm color={'error'}>{errorRequest}</TypographyForm>
          </Grid>
        }
        <Grid container item xs={12} justifyContent={'center'}>
          <ButtonForm variant='contained' color='primary' onClick={() => {
            setErrorRequest("")
            setStep(6)
          }}>¡Sube tu foto!</ButtonForm>
        </Grid>
      </Grid>
    )
  };

  const giftForm = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TypographyForm align='center' sx={{ fontSize: { xs: '1.25rem', md: '1.75rem', lg: '1.75rem', xl: '2rem' } }}>Registro guardado exitosamente!!!</TypographyForm>
        </Grid>
        {points != '' &&
          <Grid item xs={12}>
            <TypographyForm align='center'>{points}</TypographyForm>
          </Grid>
        }
        {points != '' &&
          <Grid container item xs={12} justifyContent={'center'}>
            <ButtonForm variant='contained' color='primary' onClick={() => { setStep(4); setPoints(''); giftFormik.resetForm() }}>Registrar producto</ButtonForm>
          </Grid>
        }
        {lotameProduct && <img src="https://bcp.crwdcntrl.net/5/c=17438/b=111218570" width="1" height="1" />}
      </Grid>
    )
  };

  const photoForm = () => {
    return (
      <Grid container spacing={0} direction={'row'}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: "100%",
              height: { xs: 75, md: 200 },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '2px solid #003387',
              paddingTop: 5,
              paddingBottom: 5,
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: { xs: 0, md: 10 },
              borderTopRightRadius: { xs: 10, md: 0 }
            }}
          >
            <img
              src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/EjemploFoto.svg'}
              alt="EjemploFoto"
              width={"100%"}
              height={'100%'}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <input
            type="file"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="file-input"
          />
          <label htmlFor="file-input">
            <Box
              sx={{
                width: "100%",
                height: { xs: 75, md: 200 },
                display: 'block',
                alignContent: 'center',
                textAlign: 'center',
                border: '2px dashed #003387',
                paddingTop: 5,
                paddingBottom: 5,
                borderTopRightRadius: { xs: 0, md: 10 },
                borderBottomRightRadius: 10,
                borderBottomLeftRadius: { xs: 10, md: 0 },
                borderTop: { xs: 'none', md: '2px dashed #003387' },
                borderLeft: { xs: '2px dashed #003387', md: 'none' },
                cursor: 'pointer'
              }}
            >
              {productFormik.values.photoSelected ?
                <img
                  src={productFormik.values.photoSelected}
                  alt="FotoUpload"
                  width={"80%"}
                  height={"80%"}
                />
                :
                <TypographyForm><Image /></TypographyForm>
              }
              <TypographyForm>Seleccionar foto</TypographyForm>
            </Box>
          </label>
        </Grid>
        {
          productFormik.values.photo != null &&
          <Grid item xs={12} marginLeft={1}>
            <TypographyForm>Imagen: {productFormik.values.photo.name}</TypographyForm>
          </Grid>
        }
        {
          productFormik.errors.photo && productFormik.touched.photo &&
          <Grid item xs={12} marginLeft={1}>
            <TypographyForm color={'error'}>{productFormik.errors.photo}</TypographyForm>
          </Grid>
        }
        {productFormik.errors.product && productFormik.touched.product &&
          <Grid item xs={12} marginLeft={1}>
            <TypographyForm color={'error'}>{productFormik.errors.product}</TypographyForm>
          </Grid>
        }
        {productFormik.errors.code && productFormik.touched.code &&
          <Grid item xs={12} marginLeft={1}>
            <TypographyForm color={'error'}>{productFormik.errors.code}</TypographyForm>
          </Grid>
        }
        {productFormik.errors.lote && productFormik.touched.lote &&
          <Grid item xs={12} marginLeft={1}>
            <TypographyForm color={'error'}>{productFormik.errors.lote}</TypographyForm>
          </Grid>
        }
        <Grid container item xs={12} justifyContent={'center'} marginTop={2}>
          <ButtonForm variant='contained' color='primary' sx={{ color: 'white' }} onClick={() => { setStep(4) }}>
            Regresar
          </ButtonForm>
          <ButtonForm
            variant='contained'
            color='secondary'
            sx={{ color: '#003387' }}
            onClick={() => {
              productFormik.submitForm()
            }}
            disabled={successRegister}
          >
            Enviar
          </ButtonForm>
        </Grid>
      </Grid >
    )
  };


  const renderForm = () => {
    switch (step) {
      case 1:
        return initForm();
        break;

      case 2:
        return phoneForm()
        break;
      case 3:
        return userForm()
        break;

      case 4:
        return productForm()
        break;

      case 5:
        return giftForm()
        break;

      case 6:
        return photoForm()
        break;
    }
  }

  return (
    <Grid container item spacing={2} sx={{ marginTop: 20 }}>
      <Grid item xs={0} md={2}></Grid>
      <Grid item xs={12} md={4} display={"flex"} justifyContent={"center"} margin={"auto"}>
        {xs && (
          <ImageWhatsMobile
            onClick={() => {
              handleLotameWhatsapp()
              window.location.href = 'https://api.whatsapp.com/send/?phone=525588392293&text=Hola'
            }}>
            <img
              width={"100%"}
              src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/CuadrosDeDialogo.svg'}
              alt="DanoneFresa"
            />
            {lotameWhatsapp && <img src="https://bcp.crwdcntrl.net/5/c=17438/b=111617626" width="1" height="1" />}
          </ImageWhatsMobile>
        )}
        {md && (
          <ImageWhats
            onClick={() => {
              handleLotameWhatsapp()
              window.location.href = 'https://api.whatsapp.com/send/?phone=525588392293&text=Hola'
            }}>
            <img
              width={"100%"}
              src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/CuadrosDeDialogo.svg'}
              alt="DanoneFresa"
            />
            {lotameWhatsapp && <img src="https://bcp.crwdcntrl.net/5/c=17438/b=111617626" width="1" height="1" />}
          </ImageWhats>
        )}
        <Card variant="outlined" sx={{ borderRadius: 20, padding: 1, margin: 1, width: '100%' }}>
          <CardHeader
            title={
              <TypographyForm
                align='center'
                sx={{
                  fontSize: { xs: '1.5rem', md: '1.8', lg: '1.8', xl: '2.25rem' }
                }}
              >
                Registro de códigos de barras
              </TypographyForm>} />
          <CardContent sx={{ width: "100%", height: 350, padding: 0, display: "flex", justifyContent: 'center', alignItems: 'center' }}>
            {renderForm()}
          </CardContent>
        </Card>

      </Grid>
      <Grid item xs={12} md={4} display={"flex"} justifyContent={"center"}>
        <ImageContainer
          src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/danone_fresa.svg'}
          alt="DanoneFresa"
          onClick={() => { setDisplay(true) }}
        />
      </Grid>
      <Grid item xs={0} md={2}></Grid>
    </Grid >

  )
}

export { Signup };
