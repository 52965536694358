import { TextField, Checkbox, Typography, Button, Box } from "@mui/material";
import styled from "@emotion/styled";

const TextFieldForm = styled(TextField)`
  .MuiInputBase-input{
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: bold;
  }
  .MuiInputLabel-root{       
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: bold;
  }
  .MuiOutlinedInput-notchedOutline	{    
    border-radius: 50px;
    border-color: ${({ theme }) => theme.palette.primary.main};
    border-width: medium;
    margin:5px
  }
  `;
//    &:hover .MuiOutlinedInput-notchedOutline {
//   border-color: ${({ theme }) => theme.palette.grey.main};
//   border-width: medium;
// }
//   &:hover .MuiInputLabel-root{       
//   border-color: ${({ theme }) => theme.palette.grey.main};
//      font-weight: bold;
//   }

//   &.Mui-focused .MuiOutlinedInput-notchedOutline {
//     border-width: medium;
//     border-color: ${({ theme }) => theme.palette.grey.main};
//   }

const CheckboxForm = styled(Checkbox)`
  `;

const TypographyForm = styled(Typography)`
    font-weight:bold;
  `;

const ButtonForm = styled(Button)`
    font-weight:bold;
    margin-left: 5px;
    margin-rigth: 5px;
  `;

const ImageContainer = styled('img')(({ theme }) => ({
  width: '80%',
  overflow: 'hidden',
  position: 'relative',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.2)',
    cursor: "pointer"
  },
}));

const ImageWhats = styled('div')(({ theme }) => ({
  width: '25%',
  overflow: 'hidden',
  position: 'absolute',
  transition: 'transform 0.3s ease-in-out',
  transform: 'translate(50%, -60%)',
  '&:hover': {
    transform: 'scale(1.2) translate(50%, -60%)',
    cursor: "pointer"
  },
}));

const ImageWhatsMobile = styled('div')(({ theme }) => ({
  width: '100%',
  overflow: 'hidden',
  position: 'absolute',
  transition: 'transform 0.3s ease-in-out',
  transform: 'translate(-25%, -60%)',
  '&:hover': {
    transform: 'scale(1.2) translate(-25%, -60%)',
    cursor: "pointer"
  },
}));


export { TextFieldForm, CheckboxForm, TypographyForm, ButtonForm, ImageContainer, ImageWhats, ImageWhatsMobile };