import styled from "@emotion/styled";

const ImageContainer = styled('img')(({ theme }) => ({
  width: '100%',
  overflow: 'hidden',
  position: 'relative',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.2)',
    cursor: "pointer"
  },
}));


export { ImageContainer };