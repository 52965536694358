import React, { useState, useEffect } from "react";
import { FondoHeadStyled } from './LandingStyles'
import { Divider } from '@mui/material'
import { Menu } from '../../components/Menu';
import { CTA } from "../../components/CTA";
import { Signup } from "../../components/Signup";
import { Steps } from "../../components/Steps";
import { Rewards } from "../../components/Rewards";
import { Products } from "../../components/Products";
import { Ranking } from "../../components/Ranking";
import { Footer } from "../../components/Footer";
import { Instructions } from "../../components/Instructions";
import { RewardModal } from "../../components/RewardModal";
import { RewardDailyModal } from "../../components/RewardDailyModal";
import { CookiesModal } from "../../components/CookiesModal";
import Cookies from 'js-cookie';

const Landing = () => {
  const [display, setDisplay] = useState(false);
  const [reward, setReward] = useState(false);
  const [rewardDaily, setRewardDaily] = useState(false);
  const [cookies, setCookies] = useState(false);
  const [cuponDaily, setCuponDaily] = useState('');
  const [idUser, setIdUser] = useState(0);
  const [idBarcode, setIdBarcode] = useState(0);

  useEffect(() => {
    const value = Cookies.get('cookies');
    if (!value) {
      setCookies(true)
    }
  }, []);

  return (

    <FondoHeadStyled id={'Participa'}>
      <Menu />
      <CTA />
      <Signup setDisplay={setDisplay} setReward={setReward} setIdUser={setIdUser} idUser={idUser} setDisplayDaily={setRewardDaily} rewardDaily={reward} setCuponDaily={setCuponDaily} setIdBarcode={setIdBarcode} />
      <Steps />
      <Divider orientation="horizontal" sx={{ borderColor: '#d9d9d9', borderWidth: 5, borderStyle: 'solid' }} />
      <Divider orientation="horizontal" sx={{ borderColor: 'transparent', borderWidth: 2, borderStyle: 'solid' }} />
      <Divider orientation="horizontal" sx={{ borderColor: '#d9d9d9', borderWidth: 5, borderStyle: 'solid' }} />
      <Rewards />
      <Divider orientation="horizontal" sx={{ borderColor: '#d9d9d9', borderWidth: 5, borderStyle: 'solid' }} />
      <Divider orientation="horizontal" sx={{ borderColor: 'transparent', borderWidth: 2, borderStyle: 'solid' }} />
      <Divider orientation="horizontal" sx={{ borderColor: '#d9d9d9', borderWidth: 5, borderStyle: 'solid' }} />
      <Products />
      <Divider orientation="horizontal" sx={{ borderColor: '#d9d9d9', borderWidth: 5, borderStyle: 'solid' }} />
      <Divider orientation="horizontal" sx={{ borderColor: 'transparent', borderWidth: 2, borderStyle: 'solid' }} />
      <Divider orientation="horizontal" sx={{ borderColor: '#d9d9d9', borderWidth: 5, borderStyle: 'solid' }} />
      <Ranking />
      <Footer />
      <Instructions display={display} setDisplay={setDisplay} />
      <RewardModal display={reward} setDisplay={setReward} idUser={idUser} idBarcode={idBarcode} />
      <RewardDailyModal display={rewardDaily} setDisplay={setRewardDaily} cuponUrl={cuponDaily} setCuponUrl={setCuponDaily} />
      <CookiesModal display={cookies} setDisplay={setCookies} />
    </FondoHeadStyled>
  )
}

export { Landing };
