import React, { useEffect } from "react";
import { Box, Grid, IconButton, Modal, Typography, useMediaQuery } from '@mui/material'
import { Close } from '@mui/icons-material';
import styled from "@emotion/styled";

const FondoStyled = styled(Box)
  (({ theme }) => ({
    backgroundImage: `url('https://danone50anos-cdn.mywebserver.in/assets2/svg/confetis_head.svg')`,
    backgroundRepeat: 'repeat',
    backgroundSize: 'cover',
    minHeight: '100vh',
    backgroundColor: '#003387',
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center'
  }));


const RewardDailyModal = ({ display, setDisplay, cuponUrl, setCuponUrl }) => {

  const downloadCupon = () => {
    // Crear un enlace de descarga
    var link = document.createElement('a');
    link.href = cuponUrl;
    link.download = 'cupon.png'; // Nombre del archivo que se descargará

    // Añadir el enlace al documento y hacer clic en él
    document.body.appendChild(link);
    link.click();

    // Remover el enlace del documento
    document.body.removeChild(link);
  }

  const xs = useMediaQuery('(max-width: 899px)');
  const md = useMediaQuery('(min-width: 900px)');

  return (
    <Modal
      open={display}
    >
      <FondoStyled>
        <IconButton style={{ color: 'white' }}
          sx={{ position: "absolute", top: 0, right: 0 }}
          onClick={() => {
            setDisplay(false)
            setCuponUrl('')
            // downloadCupon()
          }}
        >
          <Close
            sx={{ fontSize: { xs: 48, md: 72 } }}
          />
        </IconButton>
        <Typography color={"white"} align="center" fontWeight={'bold'} sx={{ fontSize: { xs: 22, md: 48 } }}>
          ¡Felicidades!
        </Typography>
        <Typography color={"white"} align="center" fontWeight={'bold'} sx={{ fontSize: { xs: 22, md: 48 }, marginBottom: { xs: 1, md: 1 } }}>
          Eres un posible ganador de:
        </Typography>

        {xs && (
          <img
            src={cuponUrl}
            alt='cupon'
            width={'80%'}
            style={{
              boxShadow: '0 0 20px 10px rgba(255, 255, 255, 0.5)'
            }}
          />
        )}
        {md && (
          <img
            src={cuponUrl}
            alt='cupon'
            height={'40%'}
            width={'25%'}
            style={{
              boxShadow: '0 0 20px 10px rgba(255, 255, 255, 0.5)'
            }}
          />
        )}

        <Grid container item spacing={0} sx={{ backgroundColor: '#003387', marginTop: { xs: 1, md: 1 } }}>
          <Grid item xs={1} ></Grid>
          <Grid item xs={10} >
            <Typography color={"white"} align="left" fontWeight={'bold'} sx={{ fontSize: { xs: 10, md: 16 }, marginBottom: 10, width: "100%" }} component={'span'}>
              Envía un correo en 48hrs como máximo a: promo50aniversario@futuregroup.com.mx
            </Typography>
            <br />
            <Typography color={"white"} align="left" fontWeight={'bold'} sx={{ fontSize: { xs: 10, md: 16 }, marginBottom: 10, width: "100%" }} component={'span'}>
              Para comenzar con el proceso de validación con la siguiente información compaternos:
            </Typography>
            <br />
            <Typography color={"white"} align="left" fontWeight={'bold'} sx={{ fontSize: { xs: 10, md: 16 }, marginBottom: 10, width: "100%" }} component={'span'}>
              {'a) Fotografía de tu identificación'}
            </Typography>
            <br />
            <Typography color={"white"} align="left" fontWeight={'bold'} sx={{ fontSize: { xs: 10, md: 16 }, marginBottom: 10, width: "100%" }} component={'span'}>
              {'b) Fotografía de tus envases marcados con tu nombre registrados en el día en el cual obtuviste el premio diario.'}
            </Typography>
            <br />
            <Typography color={"white"} align="left" fontWeight={'bold'} sx={{ fontSize: { xs: 10, md: 16 }, marginBottom: 10, width: "100%" }} component={'span'}>
              {'*Deberás conservar tus productos registrados en buen estado, en caso de que sea necesario y sean solicitados por el responsable de la promoción'}
            </Typography>
            <br />
            <Typography color={"white"} align="left" fontWeight={'bold'} sx={{ fontSize: { xs: 10, md: 16 }, marginBottom: 10, width: "100%" }} component={'span'}>
              {'c) Captura de que eres un \"posible ganador\"'}
            </Typography>
          </Grid>
          <Grid item xs={1} ></Grid>
        </Grid>

      </FondoStyled>
    </Modal >
  )
}

export { RewardDailyModal };
