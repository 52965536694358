import { Grid } from "@mui/material";
import styled from "@emotion/styled";


const FondoHeadStyled = styled(Grid)`
  background-image: url('https://danone50anos-cdn.mywebserver.in/assets/svg/confetis_head.svg');
  background-repeat: repeat;
  background-size: contain;
  margin-top: 75px;
  `;

export { FondoHeadStyled };