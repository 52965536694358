import React, { useState } from "react";
import { Box, Backdrop, Typography, Button, Grid } from '@mui/material'
import styled from "@emotion/styled";
import Cookies from 'js-cookie';

const FondoModalStyled = styled(Box)
  (({ theme }) => ({
    backgroundColor: '#C4E7F9',
    color: '#003387',
    borderRadius: 20,
    left: 0,
    bottom: 0
  }));

const CookiesModal = ({ display, setDisplay }) => {

  const handleCookie = (accept) => {
    Cookies.set('cookies', accept, { expires: 7 });
    setDisplay(false)
  }

  const handleCookieRedirect = () => {
    window.location.href = 'https://www.danone.com.mx/';
  }

  return (
    <Backdrop
      open={display}
      sx={{ backgroundColor: 'transparent', zIndex: 20 }}
    >
      <FondoModalStyled sx={{ width: { xs: '100%', md: '33%' }, position: { xs: 'flex', md: 'absolute' }, alignSelf: 'flex-end', padding: { xs: '18px 24px', md: '20px 48px' } }}>
        <Typography align="center">
          <img
            src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/DanoneCookies.svg'}
            alt='DanoneCookies'
          />
        </Typography>
        <Typography color={'error'} fontWeight={'bold'} sx={{ fontSize: { xs: 16, md: 28 } }}>
          Uso de Cookies
        </Typography>
        <br />
        <Typography fontWeight={'bold'} sx={{ fontSize: { xs: 12, md: 14 } }}>
          CONTENIDO DIRIGIDO A +13 AÑOS
        </Typography>
        <br />
        <Typography fontWeight={'bold'} sx={{ fontSize: { xs: 12, md: 14 } }}>
          Nosotros y terceros seleccionados utilizamos cookies o tecnologías similares con fines técnicos y, con su consentimiento, para “mejora de la experiencia” y “medición” según se especifica en la política de cookies. Denegar el consentimiento puede provocar que las características relacionadas no estén disponibles.
        </Typography>
        <br />
        <Typography fontWeight={'bold'} sx={{ fontSize: { xs: 12, md: 14 } }}>
          Usted es libre de otorgar, denegar o revocar su consentimiento en cualquier momento.
        </Typography>
        <br />
        <Typography fontWeight={'bold'} sx={{ fontSize: { xs: 12, md: 14 } }}>
          En caso de venta de su información personal, puede inhabilitar esta opción utilizando el enlace "No Vendan Mi Información Personal".
        </Typography>
        <Grid container item xs={12} justifyContent={{ xs: 'center', md: 'right' }} marginTop={1}>
          <Button variant='contained' color='secondary' sx={{ fontWeight: 'bold', margin: 1, color: '#003387' }} onClick={() => { handleCookieRedirect() }}>Rechazar</Button>
          <Button variant='contained' color='secondary' sx={{ fontWeight: 'bold', margin: 1, color: '#003387' }} onClick={() => { handleCookie(true) }}>Aceptar</Button>
        </Grid>
      </FondoModalStyled>

    </Backdrop >
  )
}

export { CookiesModal };
