import React, { useState } from "react";
import { Backdrop, Grid, IconButton, useMediaQuery } from '@mui/material'
import { Close } from '@mui/icons-material';

const Instructions = ({ display, setDisplay }) => {
  const xs = useMediaQuery('(max-width: 899px)');
  const md = useMediaQuery('(min-width: 900px)');
  return (
    <Backdrop
      sx={{ color: '#fff', backgroundColor: 'rgba(0, 0, 0, .8)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={display}
    >
      <IconButton color="inherit"
        sx={{ position: "absolute", top: 0, right: 0 }}
        onClick={() => { setDisplay(false) }}
      >
        <Close
          sx={{ fontSize: 72 }}
        />
      </IconButton>
      <Grid container item spacing={2} direction={{ xs: "row", md: "column" }} style={{ height: "100%" }} sx={{ display: { xs: "flex", md: "contents" } }}>
        <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          {xs && (
            <img
              src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Modal/CODIGO_TAPA.svg'}
              alt="TapaProduct"
              height={'75%'}
              width={'75%'}
            />
          )}
          {md && (
            <img
              src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Modal/CODIGO_TAPA.svg'}
              alt="TapaProduct"
              height={'50%'}
              width={'50%'}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          {xs && (
            <img
              src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Modal/CODIGO_BOTE.svg'}
              alt="BoteProduct"
              height={'75%'}
              width={'75%'}
            />
          )}
          {md && (
            <img
              src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/Modal/CODIGO_BOTE.svg'}
              alt="BoteProduct"
              height={'50%'}
              width={'50%'}
            />
          )}
        </Grid>
      </Grid >
    </Backdrop>
  )
}

export { Instructions };
